/* .modal-body {
    padding: 21px;
    margin: 0px;
    display: block;
} */

.ticket-title {
    margin-top: 20px !important;
    margin-bottom: 9px !important;
    font-size: 14px !important;
    font-weight: 600;
    color: #343434 !important;
    display: block;
}
.ticket-title:first-child {
    margin-top: 0px !important
}

.header-divider {
    height: 1px;
    background-color: #d9d9d9;
    margin-top: 20px;

}

.ticket-subject-desc {
    width: 100%;
    padding: 11px;
    border-radius: 2px;
}

.drag-img {
    /* opacity: 0.07; */
    width: 234px !important;
    background-color: #F0F4FA !important;
    padding: 12px !important;
    /* padding-bottom: 0px !important; */
}

/* .upload-img-border {
    border-radius: 2px;
    border: dashed 2px #2960ac;
    padding: 26px 14px 25px;
    text-shadow: 0 3px 12px rgba(0, 0, 0, 0.16);
    font-size: 16px;
    color: #333;
} */

.drag-file {
    font-size: 16px;
}
/* .browse-img {
    color: #2960ac;
    font-weight: 600;
    margin-left: 3px;
} */

.create-ticket-modal .ant-modal-header {
    border-bottom: none;
}

.preview-files {
    display: flex;
    /* justify-content: flex-start; */
    align-items: center;
    margin-top: 10px;
}

.preview-files > div {
    margin-left: 10px;
}

.preview-file {
    border-radius: 5px;
}

.preview-image-container{
    position: relative !important;
    width: 90px;
}

.close-image:hover {
    opacity: 1 !important;
}

.close-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s;
}

.close-image > span {
    margin-right: 5px;
}

.general-file {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.file-name {
    margin-top: 5px;
}

.zendesk-file-warning {
    display: flex;
    margin-top: 10px;
    /* justify-content: center; */
    align-items: center;
    font-size: 12px;
}
.zendesk-file-warning svg {
    margin-right: 5px;
    color: rgba(0, 0, 0, 0.65) !important;
}

