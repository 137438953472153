.bread-crumb-container {
    display: flex;
    margin-left: 32px;
}


/* .bread-crumb:hover {
    cursor: pointer;
} */

.article-list-container {
    background-color: #fff;
    padding: 32px;
    margin-top: 20px;
    min-height: calc(100vh - 380px);
}

.article-list-card {
    width: 65vw;
    padding: 24px;
    border: 1px solid #f0f0f0;
}
.article-name:hover {
    cursor: pointer;
}

.article-name {
  font-size: 17px;
  font-weight: 600;
}

.article-list-card  p {
    margin: 0px;
    font-size: 17px;
    color: #343434;
}

.article-no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    min-height: calc(100vh - 440px);
}

.zendesk-article-description {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.zendesk-article-readmore {
    margin-left: 6px;
    font-size: 17px;
    color: #2960ac;
}
.zendesk-article-readmore:hover {
    cursor: pointer;
}