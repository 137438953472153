.preview-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.preview-status {
    border: solid 1px #d9d9d9;
    border-radius: 3px;
    padding: 4px 5px 3px;
    font-size: 12px !important;
    margin-left: 13px;
    width: 70px;
    height: 24px;
    text-align: center;
}

.Open {
    background-color: #d4e4ec;
}

.Pending {
    background-color: #d5d5d5;  
}

.Closed {
    background-color: #f5f5f5;
}

.preview-more {
    width: 28px;
    height: 28px;
    border-radius: 15px;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.close-ticket {
    padding: 8px 11px;
}

.close-ticket-text {
    font-size: 16px !important;
}

.title-Container {
    width: 100vw;
    display: flex;
    justify-content: space-between;
}