.deleteButton {
    border: 1.5px solid #d9d9d9;
    border-radius: 4px;
    color:rgba(0, 0, 0, 0.65)
}
.deleteButton:hover {
    border: 1.5px solid #d44444 !important;
    background-color: #ffeded !important;
    color: #d44444 !important;
}


