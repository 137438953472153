.zendesk-ticket-body {
    margin-top: 19px;
    background-color: #fff;
    padding: 21px 10px 65px 32px;
    min-height: calc(100vh - 380px);
    display: flex;
    flex-direction: column;
}
.desc-body {
width: 100%;
}
.detail-desc-text {
    color: #1a324a;
    font-size: 17px;
}
.detail-desc-title {
    color: #1a324a;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 0px !important;
}

.article-body {
    display: flex;
    justify-content: space-between;
    margin-right: 25px;
}

.ticket-stats {
  max-width: 302px;
  height: 159px;
  border-radius: 3px;
  background-color: #f5f5f5;
  padding: 21px;
}

.ticket-stats div{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.stats-title {
    color: #1a324a;
    font-size: 16px !important;
    display: block;
    font-weight: 600;
}

.stats-text{
    color: #1a324a;
    font-size: 15px !important;
    margin-bottom: 21px;
}

.update-container {
    font-size: 12px;
    margin-bottom: 8px;
    color: rgba(26, 50, 74, 0.6) !important;
}