@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less'; // Import Ant Design styles by less entry
@import './theme.less'; // variables to override above
@import 'react-quill/dist/quill.snow.css';
// @import '~antd/dist/antd.dark.less'; // Introduce the official dark less style entry file
// @import '~antd/dist/antd.compact.less'; // Introduce the official compact less style entry file

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(236, 240, 241);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ql-editor {

  min-height: 200px !important;
}
:global(.sLuJd){
  color: red !important;
  margin-top: 20px;
}



.ant-layout-header {
  padding-right: 1rem;
  padding-left: 8px;
    }

