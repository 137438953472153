.tag-input {
    display: block;
}

.edit-tag {
  text-align: left;
  margin-bottom: 10px;
}


.excluded {
    background-color: #f2f2f2 !important;
    border: solid 1px #d9d9d9 !important;
    margin-left: 0px !important;
    margin-right: 8px !important;
    color: #f70c0c;
    margin-bottom: 6px !important;
}

.edit-tag span {
  object-fit: contain;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #343434;
}

.edit-tag span > svg {
    font-size: 10px;
}

.ant-tag{
    background-color: #d9e7ee;
    margin: 0 0 0 8px;
    padding: 1px 6px;
    object-fit: contain;
    border-radius: 4px;
    border: solid 1px #c7d3d9;
}


.del-item {
    margin-left: 10px;
}

.name-label {
    font-size: 14px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #343434;
}

.search-label-icon {
    margin-bottom: 8px
}

.discover-modal .ant-modal-body{
    padding-top: 4px;
    padding-left: 0px;
    padding-right: 0px;
}

.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
    left: 2px;
}

.modal-form {
    padding-right: 24px;
    padding-left: 24px;
}

.ant-tooltip-content {
    margin-left: -8px;
    width: 172px;
}
 .modal-divider {
    width: 431px;
    height: 0;
    margin: 0 0 22px 0;
    object-fit: contain;
    border: solid 1px #d9d9d9;
 }