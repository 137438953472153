.table-dashed-button {
  color: #2960ac !important;
  border-color: #2960ac !important;
}

.attract-status .ant-table-column-sorter {
  display: none;
}

.attract-status .attract-status-icon {
  color: darkgray;
  font-size: large;
}






.popup_show>.ant-modal-content>.ant-modal-body{
    padding: 16px !important;
}