.ant-form-item input {
  border-radius: 5px;
}

.create-job-warning {
  align-items: center;
  margin: 10px;
  padding: 3px;
  border: solid 1px #94d6fd;
  background-color: #e7f7ff;
}

.create-job-warning-img {
  width: 40px;
  height: 40px;
}

.create-job-warning-text {
  text-align: justify;
  font-size: 13px;
  color: #000;
  font-weight: 500;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover{
  border-bottom: none !important;
}

.ql-editor{
  min-height: 0 !important;
  padding:0 !important;
}




/* .ant-modal-confirm .ant-modal-body {
  border:1px solid red !important;
  padding:1rem !important
} */