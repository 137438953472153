.job-desc-container {
    width: 20%;
    margin-right: 8px;
    padding: 8px 0px 0px 8px;
}

.job-desc-id-location-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.job-desc-id-location {
    width: 90%;
    /* border: 1px solid green; */
}
.job-desc-fields {
    font-size: 16px;
    font-weight: 600;
    color: #343434;
    margin-right: 5px;
}
.job-desc-id-location-container svg {
    width: 24px;
    height: 23px;
}
.job-id {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
}

.job-desc-divider {
    border: 1px solid #d9d9d9;
    margin: 7.5px 0px 0px -4px;
}
.table-title-divider {
    border: 1px solid #d9d9d9;
    margin-top: 7.5px;
}

.job-desc {
    margin-top: 16px;

}
.job-desc ul {
    margin-left: -22px;
}

.job-desc-detail {
    margin-top: 10px;
    height: calc(100vh - 20px);
    overflow-y: auto;
}

.job-id-value {
    margin-top: 2px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    width: 67%; 
}