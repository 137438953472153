.filtersLayout {
    /* border-left: 2px solid #2c998a; */
    border-bottom: 1px solid #e8e8e8;
    border-radius: 0px !important;
    padding: 0px 24px 0px 13px !important;
    /* margin-bottom: 18.3px; */
}

.filtersLayout > .ant-card-body {
    padding: 11px;
    padding-right: 0;
}

.filters-title {
    margin: 0px !important;
}

.filters-title > span > svg{
   margin-left: 5px;
   font-size: 14px;
}

.filters-count {
    border: solid 1px #d9d9d9;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    border-radius: 20px;
    min-width: 52px;
    height: 25px;
    justify-content: space-evenly;
}

.filters-count span:first-child {
    color: #2c998a;
    font-weight: bold;
}

.filters-count span:nth-child(2){
    display: flex;
    align-items: center;
    font-size: 12px;
    cursor: pointer;
}

.container {
    display: block;
    position: relative;
    padding-left: 27px;
    margin-bottom: 7px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default radio button */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #fff;
    border-radius: 50%;
    margin-top: 4px;
  }
  
  /* On mouse-over, add a grey background color */

  
  /* When the radio button is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #00b7c0;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:not(:checked) ~ .checkmark{
    border: solid 1px #b6b6b6;      
  }
  
  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
       top: 5px;
      left: 5px;
      width: 6px;
      height:6px;
      border-radius: 50%;
      background: white;
      border: none;
    }