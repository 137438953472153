.actions {
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    text-align: right;
    padding-right: 20px;
}

.status {
    border: solid 1px #d9d9d9;
    border-radius: 3px;
    padding: 4px 10px 3px;

}

.Open, .new {
    background-color: #d4e4ec;
}

.Pending {
    background-color: #d5d5d5;  
}

.Closed {
    background-color: #f5f5f5;
}

.ticket-dates {
    font-size: 14px !important;
    color: #1a324a !important;
} 

.ticket-table{
    min-height: 52vh;
}

.ticket-table .ant-table-filter-trigger-container {
    align-items: center;
}

.ticket-table .ant-table-filter-trigger {
    width: auto !important;
}