/* Global Styles For All Sections*/
.block-level-form {
    width: 100%;
}
.block-level-form > div {
    text-align: left;
    margin-bottom: 0px;
}
.location-form > div{
    display: block;
}

.block-level-form label {
    font-weight: 600;
}

.margin-top-row {
    margin-top: 10px;
}

.exclude-tags-area {
    margin-top: 12px;
}



.excl-typo-row h5 {
    font-size: 14px;
    color: #8c8c8c;
}
.include-tags {
    margin-top: 16px;
}

.filters-tags > span{
    margin-left: 0px;
    margin-right: 6px;
    margin-bottom: 6px;
}

.filtered-exclude-input {
    margin-top: 10px !important;
}

/* Skills */



.search-skill-row {
    margin-top: 4px;
}

.ant-input {
    border: solid 1px #d9d9d9;
}

/* Education */


/* Experience */
.job-count{
    margin-top: 12px;
}

.exp-typo-row h5{
    font-size: 14px;
    margin-top: 14px;
}
/* Company */

.comp-excl-title {
    margin-top: 10px;
}

.single-select-input { 
    position:relative;
    display: block;
}


.location-close-icon {
    background-color: #d9e7ee;
    width: 16px;
    height: 16px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding-bottom: 3px;
    cursor: pointer;
    position: absolute;
    bottom: 7px;
    right: 10px;
}


.filters-sub-heading {
    margin-top: 14px;
}

.filters-sub-heading h5 {
    font-weight: 600;
    color: #343434;
}

.exp-tags .ant-tag {
margin-left: 0px;
}

.city-postCode-container {
    border: solid 1px #d9d9d9;
    padding: 0px 16px 15px;
    border-radius: 4px;
    margin-top: 15px;
}

.city-postCode-container > div {
    width: 100%;
}