.zendesk-card-body {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.zendesk-card-body div {
    margin-right: 21;
}


/* Cards  */
.zendesk-card {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
}

.card-title {
    font-size: 20px !important;
    font-weight: 600;
    text-align: center;
    color: #343434
}

.card_description {
    color: #343434;
    font-size: 14px;
    display: block;
    line-height: 1;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    width: 180px !important;
}

.text-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    line-height: initial !important;
}

.zendesk-cards-icon-bg {
    width: 67px;
    height: 67px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 18px;
    border-radius: 3px;
}

.zendesk-card-container {
    display: flex;
    flex-wrap: wrap;
    margin: auto auto;
    row-gap: 20px !important;
    column-gap: 22px !important;
    margin-bottom: 3.5rem !important;
}

@media screen and (max-width: 1320px) {
    .zendesk-card-container {
        column-gap: 10px;
    }
}

.categories_grow {
    width: 65.3%;
    max-width: 900px;
    justify-content: flex-start;
}

.categories_default {
    width: 570px;
    justify-content: center;
}

.zendesk-cards-icon-bg svg {
    width: 40px;
    height: 40px;
}