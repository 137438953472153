.view-more {
    justify-content: center;
    align-items: center;
    text-align: center;
}

.view-more > span {
    color: #00b7c0;
    font-size: 18px;
    font-weight: 600;
    margin-top: 27px;
    cursor: pointer;
}
