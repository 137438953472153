.zendesk-header {
    padding: 0 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 19px;
    height: 56px;
}

.zendesk-header-title {
    font-size: 20px !important;
    font-weight: 600;
    color: #fff !important;
    padding-top: 10px;
}