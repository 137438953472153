.ant-input-affix-wrapper {
    border: solid 1px #d9d9d9;
}


.table-title {
    display: inline-block;
    flex: 1 1;
    padding: 8px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-height: 48px;
    margin-bottom: -1px;
    /* padding: 0 12px; */
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    background: transparent;
    /* border-bottom: 1px dotted #f0f0f0; */
    border-radius: 5px 5px 0 0;
    position: absolute;
    top:16px
}

.tag-div {
    padding: 8px 0px 16px 0px;
    border-bottom: 1px dotted #f0f0f0;
}
.empty-tag-div {
    padding-top: 16px;
    border-bottom: 1px dotted #f0f0f0;
}
/* .ant-tag {
    margin: 10px 8px 0px 0px !important;
} */
/* .ant-card-body {
    padding: 4px 12px 12px 12px !important;
} */
.tag-div{
    margin: 14px 0px 16px 0px;
}
.clear-button {
    width: 32px;
    height: 19px;
    color: #00b7c0;
    text-decoration: underline;
    font-size: 14px;
    font-weight: 600;
    margin-left: 6px;
}
.clear-button:hover {
    cursor: pointer;
}
.tag-item {
    font-size: 12px;
    font-weight: 600;
    color: #343434
}