.Filters-body {
    background-color: #fff;
    min-width: 320px !important;
    margin-right: 12px;
    display: flex;
    /* justify-content: center; */
    align-self: flex-start;
    flex-direction: column;
    height: calc(100vh - 150px);
    /* min-height: calc(100vh - 305px); */
    overflow-y: auto;
}

.container {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.container::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}