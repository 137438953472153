.tmFormCard {
    height: 490px;
}

.tmInput{
    height: 40px;
    width: 345px !important;
    display: block;
    margin-top: 12px;
}

.tmFormBtn {
    margin-top: 32px;
    width: 86px;
    height: 40px;
    font-size: 14px;
}