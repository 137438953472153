.singleAccountForm {
    display: block;
}

.singleAccountForm input, .select-job {
    height: 40px;
    width: 345px !important;
    display: block;
    margin-top: 20px;
}

.headerRightSide {
    text-align: right;
}

.headerRightSide > span{
    color: #0cb1cc;
    cursor: pointer;
}

.job-form-button{
    margin-top: 50px;
    width: 100px;
    height: 40px;
}

.job-radio{
    font-size: 14px !important;
    margin-bottom: 32px;
    margin-right: 20px;
}


.days-title {
    margin-top: 32px;
    margin-bottom: 0px ;
}
