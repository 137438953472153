.write-Message-body {
    position: absolute;
    bottom: 24px;
    z-index: 3;
    display: block;
    border-radius: 4px;
    background-color: #f7f9f9;
    padding: 8px 12px;
}
.write-Message {
    border: solid 1px #e3e3e3;
    border-radius: 4px;
    padding: 10px 10px 10px;
    background-color: #fff;
}

.form-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.message-form {
    width: 85vw;
}

.message-input {
    width: 80%;
    outline: none !important;
    border: 1px solid #fff;
}

.message-input:focus, .message-input:hover{
    outline: none !important;
    border: 1px solid #fff;
    box-shadow: none;
}

.send-message {
    padding: 0px;
    margin-left: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: none;
}

.uploaded-images .ant-upload-list {
    display: flex;
    justify-content: space-around;
}

.ant-upload.ant-upload-select-picture-card{
      display: none !important;
}

.input-file-hide {
    display: none;
}

.attachment-file {
    cursor: pointer;
}
