.search-candidates-dFlex-column > section:first-child {
    background-color: #fff;
    margin: 10px auto;
    padding: 10px ;
}

.search-candidates-dFlex-column > section:first-child div .ant-col{
    /* margin: 16px 8px 0 0; */
    /* padding: 12px 342px 12px 16px; */
    object-fit: contain;
    border-radius: 4px;
    border: solid 2px #d9d9d9;
    background-color: #f2f2f2;
    margin: auto 16px auto 0px;
    padding:10px;
    min-height: 56px;
    align-items: center;
    align-content: center;
    display: flex;
    flex-wrap: nowrap;
    overflow-y: hidden;
    max-height: 56px;
}

.search-candidates-dFlex-column > section:first-child div .ant-col:first-child{
    /* margin: 16px 8px 0 0; */
  margin-left: 0px;
  /* max-width: 30%; */
}

.search-candidates-dFlex-column > section:first-child div .ant-col:nth-child(2){
    /* margin: 16px 8px 0 0; */
  margin-left: 0px;
  /* max-width: 30%; */
}

.search-candidates-dFlex-column > section:first-child div .ant-col:nth-child(3){
    /* margin: 16px 8px 0 0; */
  display: flex;
  justify-content: space-between;
}

.search-candidates-dFlex-column > section:first-child div .ant-col:last-child{
   background-color: transparent;
   border: soldi 20px white;
   margin-left: 0px !important;
   /* max-width: 20%; */
   margin-right: 0px;
}

.search-candidates-dFlex-column > section:first-child div.ant-col:last-child, div.ant-col:first-child {
    /* background-color: green; */
    /* margin: auto 10px; */
    padding:0px;
}

.search-candidates-dFlex-column > section:first-child div.ant-col:last-child  {
    border: 1px solid #2c998a;
    /* background-color: #2c998a; */
    /* height: 50px !important; */
}

#search-title {
    color: #00bc70;
    font-size: 14px;
    font-weight: 600;
    /* line-height: 2.86; */
    letter-spacing: normal;
    text-align: left;
    object-fit: contain;
    margin: 0px;
    cursor: pointer;
}

.formatted-date{
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: normal;
    text-align: left;
    color: #c2c2c2;
    display: block;
    padding-left: 7px;
}

#location {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5715;
    letter-spacing: normal;
    text-align: left;
    color: #8c8c8c;
}

#saved-searches-layout {
    margin-top: 40px;
}

.ant-dropdown {
    /* z-index: 3 !important; */
    /* left: 567px !important;
    top: 19px !important; */
}
.custom-ant-dropdown-open .ant-dropdown-open{
    display: none;
}

#more-icon{
    font-size: 24px;
    cursor: pointer;
    opacity: 0.6;
}

#tooltip-menu-body {
    border: solid 1px #d9d9d9;
    min-width: 94px;
}

.tooltip-item {
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    display: flex;
    align-items: center;
    color: #343434;
    width: 100%;
    height: 100%;
    padding-left: 5px;
}

#tooltip-menu-item {
    margin: 0px;
    line-height: 0;
    height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    padding: auto 2px !important;
}
#tooltip-menu-item:hover {
    background-color: rgba(65, 154, 249, 0.2);
    font-weight: 600;
}

#saved-searches {
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #343434;
}

#create-new-search {
    /* width: 70%; */
    object-fit: contain;
    font-size: 1.5vw;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.36px;
    text-align: left;
    color: #ffffff;
    background-color: #2c998a;
    border: 2px solid #2c998a;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 56px;
    padding-top: 0px;
}

.query-table-row td:first-child{
    width: 45%;
}

.query-table-row td:last-child{
    text-align: center;
}

.query-table-row  thead > tr:first-child th:last-child {
    text-align: center;
}

.query-title-ellipsis {
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
}