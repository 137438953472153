.preview-message-wrapper {
    display: block;
    height: 50vh;
    width: 100%;
    overflow-y: scroll;
}

/* width */
.preview-message-wrapper::-webkit-scrollbar {
    width: 2px;
  }
  
  /* Track */
.preview-message-wrapper::-webkit-scrollbar-track {
    background: transparent; 
  }
   
  /* Handle */
.preview-message-wrapper::-webkit-scrollbar-thumb {
    background: rgba(131, 128, 128, 0.7); 
  }
  
  /* Handle on hover */
.preview-message-wrapper ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

.preview-message-body {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: nowrap;
    margin-bottom: 33px;
}

.user-icon {
    width: 42px;
    height: 42px;
    margin: 0 11px 21px 0;
    display: flex;
    justify-content: center;
    align-items: center;    object-fit: contain;
    background-color: #a2d1e0;
    color: #fff;
    border-radius: 100%
}

.preview-message-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.preview-message-user {
    font-size: 15px !important;
    font-weight: 600;
    color: #343434;
}

.preview-message-date {
    text-align: right;
    color: rgba(52, 52, 52, 0.7);  font-size: 13px;
    font-size: 13px;
}

.preview-attachement {
    margin-top: 15px;
    margin-bottom: 13px;
    display: flex;
    justify-content: flex-start;
}

.preview-image {
    width: 75px;
    height: 75px;
    margin-right: 20px;
    margin-bottom: 10px;
}

.comment-body {
    display: block;
}

.message-body-content {
    width: 100%;
    margin-right: 15px;
}

.img-download-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-downloader svg {
    width:20px;
    height: 20px;
}

.img-downloader:hover {
    cursor: pointer;
}

.file-container {
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    margin-left: -40px;
}