.homepage-footer {
    width: 90%;
    margin-left: 5%;
    text-align: center;
    display: flex;
    justify-content: space-between;
    margin-top: 33px;
}

.looking-for {
    font-size: 17px;
    color: #1a324a;

}

.create-ticket {
    color: #00b7c0;
    cursor: pointer;
}

.view-ticket {
    color: #00b7c0;
    font-size: 17px;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 5px;
}