#searchedCandidatesLayout {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    margin-top: 8px;
}

.descrip-btn {
    background-color: #00b7c0 !important;
    padding: 0px 12px;
    object-fit: contain;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #ffffff;
    font-size: 16px;
}

.filteredDataTitle {
    color: #fff !important;
    margin-bottom: 0 !important;
}

.candidate-stats {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 16px;
    margin-left: 12px;
    min-width: 162px;
    background-color: #fff;
    height: calc(100vh - 200px);
    padding-top: 16px;
  }
  
  .candidate-stats > div {
    background-color: #fafafa;
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    padding: 0px 16px;
    max-height: 78px;
    margin-bottom: 11px;
    margin-right: 9px;
    margin-left: 9px;
    border-radius: 3px;
  }

  .candidate-stats span {
    font-size: 16px;
    color: #343434;
    text-align: left;
    opacity: 0.77;
    font-weight: 600;
    /* width: 100%; */
    text-align: right;

}

.candidate-stats span:last-child {
    font-size: 20px;
}

.disabled-stats span {
    color: #dfdfdf;
}
.stat-divider {
    width: 178px;
    height: 1px;
    margin-left: 3%;
    /* margin: 19.5px 0 17.5px; */
    object-fit: contain;
    background-color: #d9d9d9;
    display: none;
}