.search-bar {
    width: 638px;
    height: 48px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 0 6px 0 rgba(0, 183, 192, 0.18);
    border: solid 1px #d9d9d9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
}

.search-input {
    width: 80%;
    outline: none !important;
    border: 1px solid #fff;
}

.search-input:focus, .search-input:hover{
    outline: none !important;
    border: 1px solid #fff;
    box-shadow: none;
}

/* .search-btn {
    background-color: #00b7c0;
    width: 82px;
    height: 33px;
    color: #fff;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
} */