.modal-title {
    margin-left: 24px;
    margin-top: 12px;
}

.modal-body {
    margin-left: 24px;
    padding-right: 3px;
}

.ant-typography {
    color: #343434
}

.modal-divider {
    margin-top: 12px;
    margin-bottom: 22px;
    border: solid 1px #d9d9d9;
}

.modal-layout {
    background-color: #fff;   
}

.edit-modal-layout {
    background-color: #fff;   
    padding: 24px;
}

.duplicate-modal-body {
    margin: auto 24px;
    
}

.export-candidate-body {
    justify-content: space-around;
}

.export-candidate-body svg{
    font-size: 22px;
}