.download-fail-page {
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
    width: 100vw;
    height: 100vh;
}

.download-fail-page h1 {
    font-size: 160px;
    margin-bottom: 0px;
    border-right: 2px solid black;
    padding-right: 40px;
    margin-right: 40px;
}

.download-fail-page p {
    margin-bottom: 0px;
    font-size: 40px;
    margin-top: 20px;
}