.banner-body {
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 53px;
    margin-bottom: 32px;
}
  
  
  
  .banner-title {
      font-size: 27px !important;
      font-weight: 600;
      color: #fff !important;
      margin-bottom: 19px;
  }