.source {
  display: inline-block;
  padding: 3px 11px 4px;
  background-color: rgba(44, 153, 138, 0.1);
  font-size: 12px;
}

.source p {
  margin: 0px;
  text-align: center;
}

.stats-row {
  flex-wrap: nowrap;
}

.stats-card {
  padding-left: 28px;
  padding-right: 28px;
  margin-right: 16px;
  margin-bottom: 16px;
  flex-grow: 1;
  flex-wrap: nowrap;
  text-align: center;
}

.stats-card:last-child {
  margin-right: 0px;
}

.table-parent-col {
  background-color: #fff;
  position: relative;
}

.best-matches {
  padding: 16px;
  background-color: #fff;
  align-items: center;
  position: absolute;
  bottom: 5px;
}

.best-matches button {
  padding: 6px 14px 7px 14px;
  object-fit: contain;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1.5px #00b7c0;
  background-color: #ffffff !;
  display: flex;
  align-items: center;
}

.best-matches button:hover {
  padding: 6px 14px 7px 14px;
  object-fit: contain;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1.5px #00b7c0;
  background-color: #ffffff;
}

.best-matches h5 {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #8c8c8c;
  margin-bottom: 0px;
}

.filtered-data-table {
  margin-top: 0px;
}

.filtered-data-table .ant-table-thead > tr > th {
  padding: 9px 16px;
}

.filtered-data-table .ant-table-thead > tr > th:first-child {
  padding-left: 8px;
}

.stats-subType {
  font-size: 14px !important;
}

.origin-column {
  width: 62px;
  padding: 4px 10px 3px;
  object-fit: contain;
  border-radius: 3px;
  border: solid 1px #d9d9d9;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.origin-column p {
  margin: 0px;
}

.profile {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.86;
  letter-spacing: normal;
  text-align: right;
  /* color: #00b7c0; */
  cursor: pointer
}

#export-selected > svg {
  width: 13px;
  margin-right: 8px;
  color: #fff;
  /* width: 20px */
}


.clear-fields {
  text-align: right;
  width: 100%;
}

.clear-fields > span{
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #00b7c0;
  text-decoration: underline;
  cursor: pointer;
}

.selectedFiltersTags div {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.selectedFiltersTags {
  background-color: #fafafa !important;
  margin: 16px;
  margin-top: 0px;
  padding-bottom: 6px;
}

.discover-table {
  text-align: center;
}



.stats-divider {
  /* background-color: #f0f2f5;
  height: 16px; */
}

.passive-table-header {
  padding:10px 0px 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.passive-table-header:first-child {
  object-fit: contain;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #343434;
}
.saving-search-container{
  display: flex;
  align-items: center;
}
.saving-search {
  margin: 4px 0px 0px 32px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
.saving-search:last-child {
  text-decoration: underline;
}
.saving-search:last-child:hover {
  cursor: pointer;
}

.disable-save-search {
  margin: 4px 0px 0px 32px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #EBEBE4;
}

.disable-save-search:hover {
  cursor: not-allowed;
  pointer-events: none;
}

.disable-save-search:last-child {
  text-decoration: underline;
}