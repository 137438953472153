.estimatorResultCards {
    display: flex;
    justify-content: center;
    align-items: center;
}

.estimatorResultCards div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
}
.estimatorResultCards div:first-child {
    margin-right: 26px;
}

.jobStatCard {
    width: 219px;
    border-radius: 5px;
    border: solid 1px #c6c6c6;
    background-color: #f7f7f7;
}

.job-stat-btn {
    /* width: 156px;
    height: 19px; */
    object-fit: contain;
    font-family: SegoeUI;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.28px;
    text-align: right;
    color: #00b7c0;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1.5px #00b7c0;
    background-color: #fff;
    padding: 5px 21px 10px;

}

