.profile-drawer .ant-drawer-content-wrapper{
  object-fit: contain;
  box-shadow: -3px 0 6px 0 rgba(0, 0, 0, 0.16);
  background-color: red !important;
}

.profile-drawer .ant-drawer-content {
  background-color: #f5f7fd;
}

.right-align {
    text-align: right;
}

.header-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #00b7c0;
  cursor: pointer;
}

.header-text > span{
    margin: auton 8px !important;
    color: #00b7c0;
    font-size: 14px
}

.profile-view-body {
  background-color: #fff;
  padding: 20px 16px 16px 15.5px;
}
.profile-view-body .ant-typography:first-child {
  margin-bottom: 0px !important;
}
.profile-view-body .ant-typography {
  margin: 3px auto 10px auto !important;
}

.profile-view-body .ant-layout {
  background-color: transparent;
}

.action-cta {
  margin-top: 12px;
}

.action-cta .ant-col{
  margin-right: 6px;
}

.delete-cta{
  object-fit: contain;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1.5px #d9d9d9;
  background-color: #ffffff;
}

.social-icons span {
 font-size: 16px;
 margin-right: 6px;
}

.social-icons .anticon-linkedin{
  color: #0077b7
}

.social-icons .anticon-facebook{
  color: #475993
}

.social-icons .anticon-twitter{
  color: #76a9ea
}

.pofile-labels {
  display: block;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #8c8c8c;
}

.pofile-labels ~ span {
  display: block;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #343434;
}

.applicant-name > span {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #343434;
}
.profile-tags {
  margin-top: 12px;
}

.profile-tags span {
margin: 0px 6px 6px 0px;
}

.profile-view-header {
  margin-bottom: 8px;
}


.score-checkbox + label {
  margin-left: 24px !important;
}

.profile-cards {
  text-align: left;
  border-left: 1px solid #00b7c0;
  margin-bottom: 18px;
}

.redacted-data {
  filter: blur(4px);
}